<script setup>
import { Head, Link } from "@inertiajs/vue3";
import HomeMainContainer from "@/Components/HomeMainContainer.vue";
import AboutUsSection from "@/Components/AboutUsSection.vue";
import ProjectsSection from "@/Components/ProjectsSection.vue";
import ContactForm from "@/Components/ContactForm.vue";

import LanguageSelector from "@/Components/LanguageSelector.vue";
import { useThemeSetting } from "@/Composable/ThemeSetting";
const { toggleTheme, isDark } = useThemeSetting();
import { computed, defineAsyncComponent } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useDisplay } from "vuetify";


const BlogSection = defineAsyncComponent(() =>
    import("@/Components/BlogSection.vue")
);

const OurWorkSection = defineAsyncComponent(() =>
    import("@/Components/OurWorkSection.vue")
);
const page = usePage();
const { mdAndUp, lgAndUp } = useDisplay();

defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },

});

function handleImageError() {
    if (!import.meta.env.SSR) {
        document.getElementById("screenshot-container")?.classList.add("!hidden");
        document.getElementById("docs-card")?.classList.add("!row-span-1");
        document.getElementById("docs-card-content")?.classList.add("!flex-row");
        document.getElementById("background")?.classList.add("!hidden");
    }
}
const locale = computed(() => {
    return page.props.locale;
});
</script>

<template>

    <Head>
        <title>{{ __t("siteSeoTitle") }}</title>
        <meta head-key="description" name="description"
            content="3Kode - شركة برمجيات متخصصة في تطوير المواقع، التطبيقات، والأنظمة الذكية. حلول تقنية مبتكرة بأعلى معايير الجودة والكفاءة لدعم نجاح أعمالك." />

        <meta head-key="keywords" name="keywords"
            content="شركة,برمجة,شركة برمجة تركية, شركة برمجة في تركيا, تطوير, تطبيقات, برمجة مواقع ويب,برمجة,تطبيقات جوال,برمجة تطبيقات جوال,شركة برمجيات," />
    </Head>
    <div class="flex flex-col pt-5 pt-md-8 sm:pb-20">
        <HomeMainContainer />
        <AboutUsSection />
        <v-no-ssr>
            <OurWorkSection />
        </v-no-ssr>
        <ProjectsSection />

        <ContactForm />
        <v-no-ssr>
            <BlogSection />
        </v-no-ssr>
        <section class="mt-16 bg-gray-100 p-8 rounded-lg">
            <h2 class="text-2xl md:text-4xl font-bold text-center">
                آراء عملائنا حول خدمات <span class="text-primarydark">ثري كود</span>
            </h2>
            <v-row class="mt-10">
                <v-col cols="12" md="4">
                    <div class="p-5 bg-white rounded-lg shadow-md h-full">
                        <p class="text-lg text-black">
                            "خدمات <span class='text-primarydark'>ثري كود</span> ساعدتنا في تطوير منصات رقمية متكاملة
                            بأحدث التقنيات، مما عزز نجاحنا في السوق."
                        </p>
                        <span class="block mt-4 font-bold text-primarydark">أحمد جمال</span>
                        <span class="text-sm text-gray-500">مدير مشروع رقمي</span>
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div class="p-5 bg-white rounded-lg shadow-md h-full">
                        <p class="text-lg text-black">
                            "التعاون مع <span class='text-primarydark'>ثري كود</span> جعل مشاريعنا الرقمية أكثر احترافية
                            وسرعة، بفضل دمج الذكاء الاصطناعي والتكنولوجيا الحديثة."
                        </p>
                        <span class="block mt-4 font-bold text-primarydark">منى خالد</span>
                        <span class="text-sm text-gray-500">مديرة تطوير أعمال</span>
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div class="p-5 bg-white rounded-lg shadow-md h-full">
                        <p class="text-lg text-black">
                            "ثري كود أثبتت جدارتها كشريك استراتيجي في بناء حلول برمجية متميزة تلبي جميع احتياجاتنا
                            التقنية."
                        </p>
                        <span class="block mt-4 font-bold text-primarydark">خالد يوسف</span>
                        <span class="text-sm text-gray-500">مدير تطوير تقني</span>
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div class="p-5 bg-white rounded-lg shadow-md h-full">
                        <p class="text-lg text-black">
                            "اختيارنا لـ <span class='text-primarydark'>ثري كود</span> كان من أفضل القرارات، حيث وفروا
                            لنا حلول برمجية مبتكرة ساعدتنا على التوسع والنمو بسرعة."
                        </p>
                        <span class="block mt-4 font-bold text-primarydark">سارة عبد الرحمن</span>
                        <span class="text-sm text-gray-500">مديرة تسويق رقمي</span>
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div class="p-5 bg-white rounded-lg shadow-md h-full">
                        <p class="text-lg text-black">
                            "التزام <span class='text-primarydark'>ثري كود</span> بالجودة والدقة جعلهم الخيار الأول
                            لشركتنا لتطوير حلول البرمجيات الحديثة والآمنة."
                        </p>
                        <span class="block mt-4 font-bold text-primarydark">يوسف إبراهيم</span>
                        <span class="text-sm text-gray-500">مدير تقنية المعلومات</span>
                    </div>
                </v-col>
                <v-col cols="12" md="4">
                    <div class="p-5 bg-white rounded-lg shadow-md h-full">
                        <p class="text-lg text-black">
                            "ثري كود تجاوزت توقعاتنا من خلال تقديم خدمات استشارية وبرمجية مبتكرة باستخدام أحدث التقنيات
                            والذكاء الاصطناعي."
                        </p>
                        <span class="block mt-4 font-bold text-primarydark">ريم الحسن</span>
                        <span class="text-sm text-gray-500">مديرة مشاريع تقنية</span>
                    </div>
                </v-col>
            </v-row>
        </section>

        <!--  -->

        <!-- <LazyWhy3KodeSection />
  <LazyOurServices />
  <LazyStartSection /> -->
    </div>
</template>
