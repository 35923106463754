<script setup>
	import { useDisplay } from "vuetify";
	const { mdAndUp } = useDisplay();
</script>
<template>
	<div class="backdrop-blur-[18px] bg-base/90 rounded-xl border-[1px] border-solid border-[#00000010] p-5 md:p-10 py-8 py-md-16 mt-8" data-aos="fade-up" >
		<div class="w-full flexpy-2 text-white">
			<div class="flex flex-col w-full md:w-5/6">
				<h1
					class="md:text-5xl text-4xl font-bold mb-10"
					v-html="__t('meaningOf3kode')"
				></h1>
				<p>
					{{ __t('aboutMeaning') }}
				</p>
			</div>
		</div>

		<div class="text-white">
			<div v-if="mdAndUp" class="mx-auto">
				<img
					alt="3kode meaning"
					loading="lazy"
					sizes="1200px"
					height="640"
					width="1100"
					:src="__t('meaning')"
					class="mx-auto"
				></img>
			</div>

			<div v-else class="flex flex-col w-full">
				<div class="w-full flex flex-col">
					<v-img
						:alt="__t('backendTitle')"
						:src="__t('backendTitle')"
						class="md:w-2/5 w-10/12 pb-2"
					></v-img>
					<p class="text-lg">
						{{ __t("backend") }}
					</p>
				</div>
				<div class="w-full flex flex-col mt-3">
					<v-img
						:alt="__t('apiTitle')"
						:src="__t('apiTitle')"
						class="md:w-2/5 w-10/12 pb-2"
					></v-img>
					<p class="text-lg">
						{{ __t("api") }}
					</p>
				</div>
				<div class="w-full flex flex-col mt-3">
					<v-img
						:alt="__t('frontendTitle')"
						:src="__t('frontendTitle')"
						class="md:w-2/5 w-10/12 pb-2"
					></v-img>
					<p class="text-lg">
						{{ __t("frontend") }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
