<script setup>
import { useDisplay } from "vuetify";

const { mdAndUp, lgAndUp, mdAndDown } = useDisplay();

const props = defineProps(["name", "role", "pic", "des", "linkedInUrl", "bigCard", "url"]);
</script>

<template>
	<div class="group flex justify-start items-start" v-if="bigCard" data-aos="fade-up">

		<div class="flex flex-col justify-start items-start w-full md:w-11/12 ">
			<div class="flex flex-row justify-start items-start w-full">

				<img loading="lazy" :alt="name" :src="pic" :width="mdAndUp ? 150 : 100" :height="mdAndUp ? 150 : 100"
					class="transition-all duration-700 rounded-xl group-hover:shadow-primarydark md:group-hover:shadow-xl
						 rtl:md:mr-10 ltr:md:ml-10 rtl:xl:mr-12 ltr:xl:ml-12 rtl:mr-3 ltr:ml-3"></img>
				<div class="flex flex-col  justify-between w-full mx-2 mx-md-6">

					<div class="flex flex-row justify-between">
						<div>
							<p class=" text-start font-semibold w-full mt-2">
								{{ __t(`${props.name}`).toUpperCase() }}
							</p>
							<p class="text-primarydark text-start">
								{{ __t(`${props.role}`) }}
							</p>
						</div>
						<!-- <Link :to="localePath(`/${props.url}`)"> -->
							<!-- <v-btn color="primarydark" v-if="lgAndUp" rounded="lg" class="w-[120px] mt-5 md:mx-3"
								density="comfortable">
								{{ __t('readMoreAboutMe') }}
							</v-btn> -->
						<!-- </Link> -->

					</div>
					<p v-if="lgAndUp" class="mt-2"> {{ __t(`${props.des}`) }}</p>

				</div>
			</div>
			<div v-if="!lgAndUp" class="p-4" data-aos="fade-up">
				<p> {{ __t(`${props.des}`) }}</p>
				<div class="flex justify-end">
					<!-- <Link :to="localePath(`/${props.url}`)"> -->
						<!-- <v-btn color="primarydark" rounded="lg" class="w-[120px] mt-5 " density="comfortable">
							{{ __t('readMoreAboutMe') }}
						</v-btn> -->
					<!-- </Link> -->

				</div>

			</div>
		</div>
	</div>


	<div class="group flex flex-col justify-center items-center mt-3 mt-md-9" v-if="!bigCard">
		<img loading="lazy" :alt="name" :src="pic" sizes="md:150px 50px" width="150" height="150"
			class="transition-all duration-700 rounded-xl group-hover:shadow-primarydark group-hover:shadow-xl"></img>
		<div class="flex flex-row-reverse justify-center items-center w-full md:w-11/12">
			<div class="flex flex-col justify-center items-center w-full">
				<p class=" text-center font-semibold w-full mt-3">
					{{ __t(`${props.name}`).toUpperCase() }}
				</p>
				<p class="text-primarydark text-center">
					{{ __t(`${props.role}`) }}
				</p>
			</div>
		</div>
	</div>
</template>
