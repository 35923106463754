<script setup>
import { onMounted, ref, inject } from "vue";
import { Link } from "@inertiajs/vue3";

import { useDisplay } from "vuetify";
const props = defineProps({
	project: Object,
});
const { mdAndUp } = useDisplay();
const __t = inject('__t');
</script>
<template>

	<v-card :image="`/assets/patterns/${project.patternName}.png`" 
		class="  backdrop-blur-[18px] bg-base/9 0 bg-white rounded-xl border-[1px] border-solid border-[#00000000] xl:h-[75vh ] 2xl:h-[600px ] h-ma x"
		>
		<v-card-text class="flex justify-center items-center h-full ">
			<div
				class="w-full h-full flex flex-col xl:flex-row  p-5 justify-center xl:justify-around items-center gap-5 xl:gap-2 0 bg-cover bg-opacity-40">
				<p v-if="project.isUnderDevelopment"
					class="text-sm md:text-lg absolute lg:start-10 lg:top-10 start-2 top-2">
					{{ __t("underDevelopment") }}
				</p>
				<div class="w-36 xl:w-64 w- flex justify-center items-center">
					<img loading="lazy" :alt="project.projectName" class="w-full"  :height="mdAndUp ? 300 : 100"
						:src="project.logo"></img>
				</div>
				<div class="flex flex-col justify-start xl:w-2/ 3 w-full px-3 xl:p-0">
					<div class="w-full">
						<!-- <img loading="lazy" :alt="project.projectName" :height="70" :src="project.title"
							class="md:min-w-[300px] mb-5 md:w-max w-full"></img> -->
 
							<h2 class="w-full  mb-3 text-xl font-bold md:text-2xl text-center text-md-start " v-html="__t(`${project.projectTitle}`)">
					
				</h2>
					</div>
					<div class="space-y-5  max-h-20 overflow-hidden">
						<p class=" h idden md:text-start md:block" v-for="item in project.parahraphsCount"
						 v-html="__t(`${project.projectName}${item}p`)">
						</p>
					</div>
					<Link  :href="route(`${project.projectName}`)" class="self-center lg:self-auto"
						:aria-label="project.projectName">
						<v-btn :aria-label="project.projectName" class="mt-5 self-center place-self-center"
							:color="`${project.projectName}-secondary`">
							<!-- {{ __t("moreAbout", { projectName: __t(`${project.projectName`.name}) } }} -->
							{{__t("moreAbout")}} {{ __t(`${project.projectName}name`) }}
						</v-btn>
					</Link>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>
