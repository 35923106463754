<script setup>
import TeamProfile from "@/Components/TeamProfile.vue";

const team = [
	{
		name: "moafakName",
		role: "moafakRole",
		des: "moafakDes",
		pic: "/assets/team/moafak1.png",
		url: "moafak",
	},
	{
		name: "samehName",
		role: "samehRole",
		des: "samehDes",
		pic: "/assets/team/sameh2.png",
		url: "sameh",
	},
	{
		name: "haniName",
		role: "haniRole",
		pic: "/assets/team/hani1.png",
		url: "",
	},
	{
		name: "gehadName",
		role: "gehadRole",
		pic: "/assets/team/gehad1.png",
		url: "",
	},
	{
		name: "kassarName",
		role: "kassarRole",
		pic: "/assets/team/kassar1.png",
		url: "",
	},
	// {
	// 	name: "abdullahName",
	// 	role: "abdullahRole",
	// 	pic: "/team/abdallah1.png",
	// 	linkedInUrl: "",
	// },
	// {
	// 	name: "khaledName",
	// 	role: "khaledRole",
	// 	pic: "/team/khaled.png",
	// 	linkedInUrl: "",
	// },
	{
		name: "iskanderName",
		role: "iskanderRole",
		pic: "/assets/team/iskander1.png",
		url: "",
	},
	{
		name: "marwaName",
		role: "marwaRole",
		pic: "/assets/team/marwa1.png",
		url: "",
	},
	{
		name: "alaaName",
		role: "alaaRole",
		pic: "/assets/team/alaa1.png",
		url: "",
	},
];
const isBigCard = (name) => {
	return name == 'moafakName' || name == 'samehName';
	// return name == 'moafakName' || name == 'samehName';
}
</script>

<template>
	<div
		class="flex flex-col justify-start backdrop-blur-[18px] bg-base/9 0 rounded-xl border-[1px] border-solid border-[#00000010] p-5 md:p-5 mt-8">
		<h1 class="font-bold text-4xl  px-5 pb-5 md:px-40 mt-9" v-html="__t('meetOurTeam')"></h1>
		<p class="pb-10 px-5 md:px-40">
			{{ __t("teamText") }}
		</p>
		<v-row class="justify-start flex-row mb-9">
			<v-col :cols="isBigCard(teamMember.name) ? '12' : '6'" :md="isBigCard(teamMember.name) ? '6' : '3'"
				:lg="isBigCard(teamMember.name) ? '6' : '2'" class="px-0 m-0" v-for="(teamMember, index) in team"
				:key="teamMember.name">

				<TeamProfile  :name="teamMember.name"
					:url="teamMember.url" :role="teamMember.role" :pic="teamMember.pic" :des="teamMember.des"
					:bigCard="isBigCard(teamMember.name) ? true : false" />
			</v-col>
		</v-row>
	</div>
</template>
