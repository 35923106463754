<script setup>
import ProjectCard from "@/Components/ProjectCard.vue";

import { onMounted, ref, inject } from "vue";

const tm  = inject('__t');
const projects = [
	{
		projectName: "ashara",
		projectTitle: "asharaLms",
		logo: "/assets/logo/ashara-logo.png",
		title: tm("asharaTitle"),
		patternName: "ashara",
		parahraphsCount: 2,
		isUnderDevelopment: false,
	},
	{
		projectName: "konnectly",
		projectTitle: "konnectlyCrm",

		logo: "/assets/logo/konnectly.svg",
		title: tm("konnectlyTitle"),
		patternName: "konnectly",
		parahraphsCount: 3,
		isUnderDevelopment: false,
	},
	{
		projectName: "threecoin",
		projectTitle: "coinCryptocurrency",
		logo: "/assets/logo/3coin.svg",
		title: tm("threeCoin"),
		patternName: "hawala",
		parahraphsCount: 1,
		isUnderDevelopment: false,
	},
	{
		projectName: "bidakka",
		projectTitle: "bidakkaTrans",

		logo: "/assets/logo/bi.svg",
		title: tm("bidakkaTitle"),
		patternName: "hawala",
		parahraphsCount: 3,
		isUnderDevelopment: false,
	},
	// {
	// 	projectName: "sesh",
	// 	logo: tm("seshLogo"),
	// 	title: tm("seshTitle"),
	// 	patternName: "sesh-pattern",
	// 	parahraphsCount: 2,
	// 	isUnderDevelopment: true,
	// },
	{
		projectName: "smartStore",
		projectTitle: "smartOnlinestore",

		logo: "/assets/logo/smartstore.svg",
		title: tm("smartStoreTitle"),
		patternName: "konnectly",
		parahraphsCount: 3,
		isUnderDevelopment: false,
	},
	{
		projectName: "ba7bsh",
		projectTitle: "ba7bshAds",

		logo: "/assets/logo/ba7bsh.svg",
		title: tm("ba7bshTitle"),
		patternName: "konnectly",
		parahraphsCount: 1,
		isUnderDevelopment: true,
	},
];
</script>
<template>
	<div>
		<div class="py-2 4 sm:py-3 2 mt-8 section" id="projects">
			<div
				class=" backdrop-blur-[18px] bg-base/9 0 rounded-xl border-[1px] border-solid border-[#00000010] p-4 p-md-8">
				<h2 class="w-full px-5 mt-8 text-3xl font-bold md:text-5xl text-start md:px-40" v-html="__t('ourProjects')">
					
				</h2>
				<p class="px-5 pt-5 mb-9  text-start sm:w-full md:w-full lg:w-1/2 md:px-40">
					{{ __t("ourProjectsDes") }}
				</p>
				<!-- 
				<h1 class="md:text-5xl text-3xl w-full font-bold pb-10  text-primarydark mt-2">
					{{ __t("ourProjects") }}
				</h1> -->
				<v-row>
					<v-col cols="12" lg="6" v-for="(project, index) in projects" :key="project.projectName">
						<ProjectCard :project="project"  />
						<!-- <ProjectCard :project="project" data-aos="fade-up" :data-aos-delay="index * 50" /> -->
					</v-col>
				</v-row>
			</div>

		</div>
	</div>
</template>
